import { ComponentPropsWithRef, forwardRef, useState, FocusEvent } from "react";
import styled from "styled-components";
import { capitalize } from "../../../format";
import { TextCapitalized } from "../../../designSystem/components/text/TextCapitalized";
import { Spacer } from "./../Spacer";
import { ElementButton } from "../../../components/ElementButton";
import { typographies } from "../../../styles/figmaTypographies";
import { colors } from "../../../styles/figmaColors";
import {
  SearchOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "../../materialUi/materialUi";

export interface InputDSProps extends ComponentPropsWithRef<"input"> {
  label?: string | JSX.Element | JSX.Element[];
  error?: string;
  withoutSpacerBottom?: boolean;
  width?: number;
  isPlaceholderUppercase?: boolean;
  isMandatory?: boolean;
  isDisabled?: boolean;
  type?: InputType;
  adornent?: string | JSX.Element;
  adornentColor?: string;
  tooltip?: JSX.Element;
  height?: number;
}

export type InputArgs = {
  type: string;
  iconLeft?: JSX.Element;
  placeholder?: string;
};

export type InputType =
  | "TEXTFIELD"
  // | "TEXTAREA"
  | "SEARCH"
  | "ADORNENTS"
  | "PHONE"
  | "PASSWORD"
  | "NUMBER";

const InputTypeProps = {
  TEXTFIELD: {
    type: "text",
  },
  // TEXTAREA: {
  //   type: "textarea",
  // },
  SEARCH: {
    type: "text",
    iconLeft: <SearchOutlined />,
  },
  ADORNENTS: {
    type: "text",
  },
  PHONE: {
    type: "text",
    iconLeft: <>🇫🇷 +33</>,
    placeholder: "06 10 20 30 40",
  },
  PASSWORD: {
    type: "password",
  },
  NUMBER: {
    type: "number",
  },
};

export const InputDS = forwardRef<HTMLInputElement, InputDSProps>(
  (
    {
      label,
      type,
      error,
      withoutSpacerBottom,
      isPlaceholderUppercase,
      width,
      isMandatory,
      isDisabled,
      adornent,
      adornentColor,
      tooltip,
      height,
      ...props
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
    if (!type) {
      type = "TEXTFIELD";
    }

    const inputProps: InputArgs = InputTypeProps[type];

    const togglePasswordVisibility = () => {
      setIsPasswordVisible((isVisible) => !isVisible);
    };

    return (
      <StyledContainer $width={width}>
        {label && (
          <StyledLabel $isDisabled={isDisabled}>
            <TextCapitalized>{label}</TextCapitalized>
            <Spacer x={0.25} />
            {isMandatory && <StyledMandatory>*</StyledMandatory>}
            {tooltip && <StyledToolTip>{tooltip}</StyledToolTip>}
          </StyledLabel>
        )}

        <StyledBackground
          $isError={error !== undefined}
          $isInputFocus={isInputFocus}
          $width={width}
        >
          <StyledInputContainer
            $isError={error !== undefined}
            $width={width}
            $isInputFocus={isInputFocus}
            $isDisabled={isDisabled}
            $height={height}
          >
            {inputProps.iconLeft && (
              <StyledAdornent>
                <Spacer x={0.75} />
                {inputProps.iconLeft}
              </StyledAdornent>
            )}
            <StyledInputValue
              $isDisabled={isDisabled}
              $isError={error !== undefined}
              ref={ref as React.Ref<HTMLInputElement>}
              {...props}
              type={isPasswordVisible ? "text" : inputProps.type}
              placeholder={
                isPlaceholderUppercase
                  ? props.placeholder
                  : inputProps.placeholder ||
                    (props.placeholder && capitalize(props.placeholder))
              }
              width={width}
              onFocus={() => {
                setIsInputFocus(true);
              }}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                props.onBlur && props.onBlur(e);
                setIsInputFocus(false);
              }}
              disabled={isDisabled}
            />
            {type === "PASSWORD" ? (
              <StyledAdornent>
                <ElementButton
                  onClick={togglePasswordVisibility}
                  Element={
                    isPasswordVisible ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )
                  }
                />
                <Spacer x={0.75} />
              </StyledAdornent>
            ) : (
              (type === "ADORNENTS" || type === "NUMBER") && (
                <StyledAdornent $adornentColor={adornentColor}>
                  {adornent}
                  <Spacer x={0.75} />
                </StyledAdornent>
              )
            )}
          </StyledInputContainer>
        </StyledBackground>
        {error && (
          <StyledErrorMessage>
            <Spacer x={0.25} />
            <TextCapitalized>{error}</TextCapitalized>
          </StyledErrorMessage>
        )}
        {!withoutSpacerBottom && <Spacer y={1.5} />}
      </StyledContainer>
    );
  },
);

const StyledContainer = styled.div<{ $width?: number }>`
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
`;

const StyledMandatory = styled.div`
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledToolTip = styled.div`
  display: flex;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledErrorMessage = styled.div`
  display: flex;
  ${typographies["Body/XXS"]};
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledLabel = styled.label<{
  $isDisabled?: boolean;
}>`
  display: flex;
  ${typographies["Body/M"]};
  border-left: solid 0.25rem transparent;
  color: ${({ $isDisabled }) =>
    $isDisabled
      ? colors["colors/text/lightGrey"]
      : colors["colors/text/black"]};
`;

const StyledInputValue = styled.input<{
  $isError: boolean;
  $isDisabled?: boolean;
  width?: number;
}>`
  ${typographies["Body/S"]};
  width: 100%;
  padding: 0.5rem;
  color: ${({ $isDisabled }) =>
    $isDisabled
      ? colors["colors/text/lightGrey"]
      : colors["colors/text/black"]};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "auto")};
  &::placeholder {
    ${typographies["Body/S"]};
    color: ${colors["colors/text/lightGrey"]};
  }
  &:focus {
    outline: none;
    background-size:
      100% 0.125rem,
      100% 0.0625rem;
  }
  background-color: ${({ $isDisabled }) =>
    $isDisabled
      ? colors["colors/surfaces/background/background_level1"]
      : colors["colors/surfaces/background/background_level0"]};
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
`;

const StyledInputContainer = styled.div<{
  $width?: number;
  $isError: boolean;
  $isInputFocus: boolean;
  $isDisabled?: boolean;
  $height?: number;
}>`
  display: flex;
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}rem` : "2rem")};
  border-radius: 0.25rem;
  border: 0.0625rem solid
    ${({ $isError, $isInputFocus }) =>
      $isError
        ? colors["colors/system/error/error_normal"]
        : $isInputFocus
          ? colors["colors/accent/500"]
          : colors["colors/borders/input/default"]};
  &:hover {
    border: 0.0625rem solid
      ${({ $isError, $isDisabled, $isInputFocus }) =>
        $isError
          ? colors["colors/system/error/error_normal"]
          : $isDisabled
            ? colors["colors/borders/input/default"]
            : !$isInputFocus
              ? colors["colors/borders/input/hover"]
              : colors["colors/accent/500"]};
  }
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  &:focus {
    outline: none;
    background-size: 100%  0.125rem, 100% 0.0625rem;
  }     
  outline: none;

`;

const StyledBackground = styled.div<{
  $isError: boolean;
  $isInputFocus: boolean;
  $width?: number;
}>`
  display: flex;
  width: ${({ $width }) => ($width ? `${$width + 2}rem` : "100%")};
  transition: all 0.4s ease-out;
  border: 0.25rem solid transparent;
  border-radius: 0.5rem;
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors["colors/accent/250"]};
`;

const StyledAdornent = styled.div<{
  $adornentColor?: string;
}>`
  ${typographies["Body/S"]};
  color: ${({ $adornentColor }) =>
    $adornentColor ?? colors["colors/text/lightGrey"]};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
  svg {
    width: 1rem;
    height: 1rem;
  }
  cursor: default;
`;
