import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  ButtonDS,
  CritAir,
  DownloadOutlined,
  EnergyClass,
  InputDS,
  NotInterestedOutlined,
  RemoveRedEyeOutlined,
  SidePanelDS,
  Spacer,
  Tag,
  TextAreaDS,
  TextCapitalized,
  dateFormatterDayMonthYear,
  dateFormatterDayMonthYearLong2Digits,
  dateFormatterHourMinute,
  uppercaseFirstLetter,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectListAllVehiclesInDb,
  updateVehicleAnalyticCodeAsync,
  updateVehicleNoteTakingAsync,
} from "./vehiclesSlice";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { DeactivateVehicleModal } from "./modals/deactivateVehicleModal";
import { selectCompany } from "../homeSlice";
import {
  driversHistoryAsync,
  selectDriversHistoryList,
} from "../fleet/fleetSlice";
import { createFileXlsx } from "@qivia/ui/src/designSystem/components/materialUiTable/createFileXlsx";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { VehiclesListType } from "./vehiclesAPI";

export const VehicleSidePanel: FunctionComponent = () => {
  const pageName = "vehicles";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const listAllVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);
  const vehicle = listAllVehiclesInDb.find((v) => v.uuid === params.id);
  const allDriversHistoryList = useAppSelector(selectDriversHistoryList);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] =
    useState(false);

  useEffect(() => {
    if (!vehicle) {
      navigate(`/home/vehicles`);
    }
  }, [navigate, vehicle]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/vehicles");
  }, [navigate]);

  const driversHistoryList = useMemo(
    () => allDriversHistoryList.filter((dh) => dh.vehicleUuid === params.id),
    [allDriversHistoryList, params.id],
  );

  const lastDriver = useMemo(() => driversHistoryList[0], [driversHistoryList]);

  const driversHistoryExcelColumns = useMemo(
    () => [
      "idNumber",
      "lastName",
      "firstName",
      "startOfUsage",
      "startOfUsageTime",
      "endOfUsage",
      "endOfUsageTime",
    ],
    [],
  );

  const handleClickDriver = useCallback(() => {
    navigate(`/home/drivers/${lastDriver?.driverUuid}`);
  }, [lastDriver?.driverUuid, navigate]);

  const handleDownloadDriverHistory = useCallback(() => {
    if (driversHistoryList.length <= 0) return;
    const headerTitles = driversHistoryExcelColumns.map((key) =>
      uppercaseFirstLetter(t(`driversHistory.column.${key}`)),
    );

    const bodyList = driversHistoryList.map((dh) => [
      dh.driverInfo.idNumber ?? "",
      dh.driverInfo.lastName,
      dh.driverInfo.firstName,
      dateFormatterDayMonthYearLong2Digits(new Date(dh.startOfUsage)),
      dateFormatterHourMinute(new Date(dh.startOfUsage)),
      dh.endOfUsage
        ? dateFormatterDayMonthYearLong2Digits(new Date(dh.endOfUsage))
        : "",
      dh.endOfUsage ? dateFormatterHourMinute(new Date(dh.endOfUsage)) : "",
    ]);

    const file = createFileXlsx({
      tabTitle: t(`${pageName}.view.title`),
      headerTitles,
      body: bodyList,
    });

    if (!file) return;
    void dispatch(
      documentDownloadXlsxAsync({
        buffer: file,
        fileName: "QIVIA_" + t("vehicles.driversHistory.excel.title") + ".xlsx",
      }),
    );
  }, [dispatch, driversHistoryExcelColumns, driversHistoryList, t]);

  useEffect(() => {
    if (company) {
      void dispatch(driversHistoryAsync(company.uuid));
    }
  }, [company, dispatch]);

  if (!vehicle || !company) {
    return;
  }

  return (
    <SidePanelDS.SidePanel
      visible={true}
      onClose={onSidePanelClosed}
      absoluteSection={
        <ButtonDS
          leftIcon={<NotInterestedOutlined />}
          text={t("vehicles.sidepanel.deactivate")}
          format={"fill"}
          buttonType={"secondary"}
          onClick={() =>
            vehicle.status !== "DEACTIVATED" &&
            setIsDeactivateModalVisible(!isDeactivateModalVisible)
          }
          iconColor={"errorNormal"}
          disabled={vehicle.status === "DEACTIVATED"}
        />
      }
    >
      <DeactivateVehicleModal
        isVisible={isDeactivateModalVisible}
        onCloseModal={() => {
          setIsDeactivateModalVisible(false);
        }}
        vehicleUuid={vehicle.uuid}
      />
      <SidePanelDS.Section
        verticalSpacing={1.5}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          <StyledContent>{vehicle.registrationNumber}</StyledContent>
          {vehicle.reference && (
            <>
              <Spacer y={0.5} />
              <Tag
                text={vehicle.reference}
                borderColor={colors["colors/borders/cells/cells"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                textColor={colors["colors/button/primary/default"]}
              />
            </>
          )}
          <StyledImg src={Car} alt="Car" />
          <Spacer y={1} />
          <StyledContent>
            {`${vehicle.brand ? vehicle.brand : ""} ${vehicle.model ? vehicle.model : ""}`.trim()}
          </StyledContent>
          {vehicle.energy && (
            <>
              <Spacer y={0.25} />
              <StyledEnergie>
                <TextCapitalized>
                  {vehicle.energy && t(`vehicles.energie.${vehicle.energy}`)}
                </TextCapitalized>
              </StyledEnergie>
            </>
          )}
        </StyledPanelContainer>
        <Spacer y={1.5} />
        {vehicle.cardUuid && vehicle.rule && (
          <SidePanelDS.ActionBlock>
            <>
              {vehicle.cardUuid && vehicle.cardName && (
                <SidePanelDS.SectionedActionStripe
                  left={
                    <StyledRow>
                      <LittleCardAsset />
                      <Spacer x={1} />
                      {`**** ${vehicle.cardName}`}
                    </StyledRow>
                  }
                  right={
                    <StyledLittleIcon>
                      <RemoveRedEyeOutlined
                        key="card"
                        onClick={() =>
                          navigate(`/home/cards/${vehicle.cardUuid}`)
                        }
                      />
                      <Spacer x={1} />
                    </StyledLittleIcon>
                  }
                />
              )}
              {vehicle.rule && (
                <SidePanelDS.SectionedActionStripe
                  left={
                    <StyledRowCenter>
                      <StyledBodyM>
                        <TextCapitalized>
                          {t(`cards.sidePanel.informations.title.rule`)}
                        </TextCapitalized>
                      </StyledBodyM>
                      <Spacer x={0.5} />
                      {vehicle.ruleName}
                    </StyledRowCenter>
                  }
                  right={
                    <StyledLittleIcon>
                      <RemoveRedEyeOutlined
                        key="rule"
                        onClick={() => navigate(`/home/rule/${vehicle.rule}`)}
                      />
                      <Spacer x={1} />
                    </StyledLittleIcon>
                  }
                />
              )}
            </>
          </SidePanelDS.ActionBlock>
        )}
      </SidePanelDS.Section>
      <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>{t("vehicles.sidepanel.details")}</TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.status")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <TextCapitalized>
              {t(`vehicles.status.${vehicle.status}`)}
            </TextCapitalized>
          }
        />
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>{t("vehicles.sidepanel.type")}</TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <Tag
              text={t(`vehicles.type.${vehicle.vehicleType}`)}
              borderColor={colors["colors/borders/cells/cells"]}
              backgroundColor={
                colors["colors/surfaces/background/background_level0"]
              }
              textColor={colors["colors/text/darkGrey"]}
            />
          }
        />
        <Spacer y={2} />
        <NoteTakingCodeSection
          noteTaking={vehicle.noteTaking}
          companyUuid={company.uuid}
          vehicleUuid={vehicle.uuid}
          vehicleUpdatedAt={vehicle.updatedAt}
        />
      </SidePanelDS.Section>

      <AnalyticCodeSection
        analyticCode={vehicle.analyticCode}
        companyUuid={company.uuid}
        vehicleUuid={vehicle.uuid}
        vehiclesList={listAllVehiclesInDb}
      />

      <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.driversHistory")}
          </TextCapitalized>
        </SectionTitle>
        {lastDriver && (
          <>
            <Spacer y={2} />
            <SidePanelDS.ActionBlock>
              <SidePanelDS.SectionedActionStripe
                left={
                  <StyledRowCenter>
                    <TextCapitalized>
                      <Avatar
                        size={"S"}
                        color={"orange"}
                        lastName={lastDriver.driverInfo.lastName}
                        firstName={lastDriver.driverInfo.firstName}
                      />
                    </TextCapitalized>
                    <Spacer x={0.5} />
                    {lastDriver.driverInfo.firstName.concat(
                      " ",
                      lastDriver.driverInfo.lastName,
                    )}
                  </StyledRowCenter>
                }
                right={
                  <StyledLittleIcon>
                    <RemoveRedEyeOutlined
                      key="driver"
                      onClick={handleClickDriver}
                    />
                    <Spacer x={1} />
                  </StyledLittleIcon>
                }
              />
            </SidePanelDS.ActionBlock>
            <Spacer y={1} />
            <ButtonDS
              sizeButton="S"
              format="hug"
              buttonType="secondary"
              text={t("vehicles.sidepanel.downloadDriversHistoryList")}
              leftIcon={<DownloadOutlined />}
              onClick={handleDownloadDriverHistory}
            />
          </>
        )}
      </SidePanelDS.Section>

      <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.informations")}
          </TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.mileage")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {!vehicle.latestMileage ? "-" : vehicle.latestMileage}
              <Spacer x={0.25} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("vehicles.sidepanel.mileage.units")}
                </TextCapitalized>
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <Spacer y={0.25} />
        {vehicle.latestMileageDate && (
          <StyledSubTitle>
            <TextCapitalized>
              {t("vehicles.sidepanel.mileageDate")}
            </TextCapitalized>
            <Spacer x={0.25} />:<Spacer x={0.25} />
            {dateFormatterDayMonthYear(new Date(vehicle.latestMileageDate))}
          </StyledSubTitle>
        )}
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.consumption.title")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {!vehicle.averageConsumption ? "-" : vehicle.averageConsumption}
              <Spacer x={0.25} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("vehicles.sidepanel.consumption.units")}
                </TextCapitalized>
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <StyledSubTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.consumption.subTitle")}
          </TextCapitalized>
        </StyledSubTitle>
      </SidePanelDS.Section>

      <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>{t("vehicles.sidepanel.pollution")}</TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.emission")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.averageCarbonEmissionPerKm === null
                ? "-"
                : vehicle.averageCarbonEmissionPerKm}
              <Spacer x={0.25} />
              <StyledSubTitle>
                {t("vehicles.sidepanel.emission.units")}
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.energyClass.title")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.energyClass === null ? (
                "-"
              ) : (
                <>
                  <EnergyClass
                    energyClass={vehicle.energyClass as EnergyClass}
                  />
                  <Spacer x={0.25} />
                </>
              )}
              <Spacer x={0.25} />
            </StyledRow>
          }
        />
        <Spacer y={0.25} />
        {vehicle.energyClass && (
          <StyledSubTitle>
            <TextCapitalized>
              {t(`vehicles.sidepanel.energyClass.${vehicle.energyClass}`)}
            </TextCapitalized>
            <Spacer x={0.25} />
          </StyledSubTitle>
        )}
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.critAir")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.critAir === null ? (
                <>
                  {"-"} <Spacer x={0.25} />
                </>
              ) : (
                <CritAir critAir={`${vehicle.critAir}`.toString() as CritAir} />
              )}
            </StyledRow>
          }
        />
      </SidePanelDS.Section>
    </SidePanelDS.SidePanel>
  );
};

const AnalyticCodeSection = (props: {
  analyticCode: string | null;
  companyUuid: string;
  vehicleUuid: string;
  vehiclesList: VehiclesListType[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [analyticCode, setAnalyticCode] = useState<string>(
    props.analyticCode ?? "",
  );
  const [displayError, setDisplayError] = useState<boolean>(false);

  const hasDuplicateAnalyticCode: boolean = useMemo(() => {
    if (analyticCode === "") return false;
    if (analyticCode === props.analyticCode) return false;
    return !!props.vehiclesList.find((v) => v.analyticCode === analyticCode);
  }, [analyticCode, props.analyticCode, props.vehiclesList]);

  const isValidAnalyticCodeInput: boolean = useMemo(() => {
    return !hasDuplicateAnalyticCode;
  }, [hasDuplicateAnalyticCode]);

  const onClick = useCallback(() => {
    setDisplayError(true);
    if (isValidAnalyticCodeInput) {
      void dispatch(
        updateVehicleAnalyticCodeAsync({
          vehicleUuid: props.vehicleUuid,
          analyticCode,
          companyUuid: props.companyUuid,
        }),
      );
      setDisplayError(false);
    }
  }, [
    analyticCode,
    dispatch,
    isValidAnalyticCodeInput,
    props.companyUuid,
    props.vehicleUuid,
  ]);

  const analyticCodeError: string | undefined = useMemo(() => {
    return displayError && hasDuplicateAnalyticCode
      ? t("vehicles.sidepanel.analyticCode.error.alreadyExists")
      : undefined;
  }, [displayError, hasDuplicateAnalyticCode, t]);

  return (
    <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
      <SectionTitle>
        <TextCapitalized>
          {t("vehicles.sidepanel.analyticCode.title")}
        </TextCapitalized>
      </SectionTitle>
      <Spacer y={1} />
      <StyledFlex>
        <InputDS
          placeholder={t("vehicles.sidepanel.analyticCode.placeholder")}
          value={analyticCode}
          onChange={(e) => setAnalyticCode(e.target.value)}
          error={analyticCodeError}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick();
              (e.target as HTMLInputElement).blur();
            }
          }}
          withoutSpacerBottom
          onBlur={() => onClick()}
        />
      </StyledFlex>
    </SidePanelDS.Section>
  );
};

const NoteTakingCodeSection = (props: {
  noteTaking: string | null;
  companyUuid: string;
  vehicleUuid: string;
  vehicleUpdatedAt: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [noteTaking, setNoteTaking] = useState<string>(props.noteTaking ?? "");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const hasLimitCharacterReached: boolean = useMemo(() => {
    if (0 <= noteTaking.length && noteTaking.length <= 300) return false;
    return true;
  }, [noteTaking]);

  const isValidNoteTakingInput: boolean = useMemo(() => {
    return !hasLimitCharacterReached;
  }, [hasLimitCharacterReached]);

  const onClick = useCallback(() => {
    setDisplayError(true);
    if (isValidNoteTakingInput) {
      void dispatch(
        updateVehicleNoteTakingAsync({
          vehicleUuid: props.vehicleUuid,
          noteTaking,
          companyUuid: props.companyUuid,
        }),
      );
      setDisplayError(false);
    }
  }, [
    dispatch,
    isValidNoteTakingInput,
    noteTaking,
    props.companyUuid,
    props.vehicleUuid,
  ]);

  const noteTakingError: string | undefined = useMemo(() => {
    return displayError && hasLimitCharacterReached
      ? t("vehicles.sidepanel.noteTaking.error.limitReached")
      : undefined;
  }, [displayError, hasLimitCharacterReached, t]);

  return (
    <StyledNote>
      <TextCapitalized>
        {t("vehicles.sidepanel.noteTaking.title")}
      </TextCapitalized>
      <Spacer y={0.25} />
      <StyledNoteDate>
        {noteTaking ? props.vehicleUpdatedAt : <Spacer y={1} />}
      </StyledNoteDate>
      <Spacer y={1} />
      <TextAreaDS
        placeholder={t("vehicles.sidepanel.noteTaking.placeholder")}
        value={noteTaking}
        update={(e: string) => setNoteTaking(e)}
        error={noteTakingError}
        onBlur={() => onClick()}
      />
    </StyledNote>
  );
};

const StyledNoteDate = styled.div`
  display: flex;
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]}
`;

const StyledSubTitle = styled.div`
  display: flex;
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SectionTitle = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/darkGrey"]}
`;

const SectionRowLabel = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]}
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledBodyM = styled.div`
  ${typographies["Body/M"]}
`;

const StyledContent = styled.div`
  ${typographies["Header/H4"]};
`;

const StyledImg = styled.img`
  filter: grayscale(100%);
  width: 15rem;
  height: 6.5rem;
  object-fit: contain;
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
const StyledLittleIcon = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledEnergie = styled.span`
  ${typographies["Body/S"]};
  color: ${colors["colors/text/darkGrey"]};
  text-align: center;
`;

const StyledFlex = styled.div`
  display: flex;
`;
