import styled from "styled-components";
import { AllowedPeriodType } from "../../rulesAPI";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { ButtonDS, DeleteOutlined, SelectTimeDS, Spacer } from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const AllowedPeriodDetails = (props: {
  oldValue: AllowedPeriodType[];
  allowedPeriod: AllowedPeriodType;
  onChange: (value: AllowedPeriodType[]) => void;
  index?: number;
  hasAllowedPeriods: boolean;
}) => {
  const { t } = useTranslation();
  const { oldValue, allowedPeriod, onChange, hasAllowedPeriods, index } = props;

  const allowedPeriodCurrentDay: AllowedPeriodType[] | null =
    oldValue.filter((ap) => ap.dayOfWeek === allowedPeriod.dayOfWeek) ?? null;

  const allowedPeriodCurrentDaySorted: AllowedPeriodType[] | null =
    allowedPeriodCurrentDay?.sort(
      (a: AllowedPeriodType, b: AllowedPeriodType) => {
        return a.startTime.localeCompare(b.startTime);
      },
    ) ?? null;

  const currentAllowedPeriodIndex =
    allowedPeriodCurrentDaySorted.findIndex(
      (ap) =>
        ap.startTime === allowedPeriod.startTime &&
        ap.endTime === allowedPeriod.endTime,
    ) ?? null;

  const lastAllowedPeriod: AllowedPeriodType | null =
    allowedPeriodCurrentDaySorted &&
    currentAllowedPeriodIndex !== null &&
    allowedPeriodCurrentDaySorted[currentAllowedPeriodIndex - 1]
      ? allowedPeriodCurrentDaySorted[currentAllowedPeriodIndex - 1]
      : null;

  const nextAllowedPeriod: AllowedPeriodType | null =
    allowedPeriodCurrentDaySorted &&
    currentAllowedPeriodIndex !== null &&
    allowedPeriodCurrentDaySorted[currentAllowedPeriodIndex + 1]
      ? allowedPeriodCurrentDaySorted[currentAllowedPeriodIndex + 1]
      : null;

  const onDeleteAllowedPeriod = useCallback(
    (allowedPeriod: AllowedPeriodType) => {
      const oldAllowedPeriods = oldValue;
      const newAllowedPeriods = oldAllowedPeriods.filter((a) => {
        return a !== allowedPeriod;
      });
      onChange(newAllowedPeriods);
    },
    [oldValue, onChange],
  );

  const updateStartTime = useCallback(
    (startTime: string) => {
      const newAllowedPeriods = oldValue.map((value) => {
        if (
          allowedPeriod.dayOfWeek === value.dayOfWeek &&
          allowedPeriod.startTime === value.startTime
        ) {
          return {
            ...allowedPeriod,
            startTime,
          };
        } else {
          return value;
        }
      });
      onChange(newAllowedPeriods);
    },
    [allowedPeriod, oldValue, onChange],
  );

  const updateEndTime = useCallback(
    (endTime: string) => {
      const newAllowedPeriods = oldValue.map((value) => {
        if (
          allowedPeriod.dayOfWeek === value.dayOfWeek &&
          allowedPeriod.endTime === value.endTime
        ) {
          return {
            ...allowedPeriod,
            endTime,
          };
        } else return value;
      });
      onChange(newAllowedPeriods);
    },
    [allowedPeriod, oldValue, onChange],
  );

  return (
    <StyledBodyDetails>
      <SelectTimeDS
        beginningTime={
          lastAllowedPeriod?.endTime
            ? Number(lastAllowedPeriod.endTime.substring(0, 2)) + 1
            : 0
        }
        endingTime={
          allowedPeriod.endTime
            ? Number(allowedPeriod.endTime.substring(0, 2)) - 1
            : 24
        }
        value={allowedPeriod ? allowedPeriod.startTime : "00:00:00"}
        onChange={(value) => updateStartTime(value ?? "00:00:00")}
        disabled={!hasAllowedPeriods}
        key={index + "selectTimeRight"}
      />
      <Spacer x={1} />
      <StyledLinkedWord $disabled={!hasAllowedPeriods}>
        {t("rules.modal.dayOfWeek.time.linkWord")}
      </StyledLinkedWord>
      <Spacer x={0.5} />
      <SelectTimeDS
        beginningTime={
          allowedPeriod.startTime
            ? Number(allowedPeriod.startTime.substring(0, 2)) + 1
            : 0
        }
        endingTime={
          nextAllowedPeriod?.startTime
            ? Number(nextAllowedPeriod.startTime.substring(0, 2)) - 1
            : 24
        }
        value={allowedPeriod ? allowedPeriod.endTime : "24:00:00"}
        onChange={(value) => updateEndTime(value ?? "24:00:00")}
        disabled={!hasAllowedPeriods}
        key={index + "selectTimeLeft"}
      />
      <Spacer x={1} />
      {hasAllowedPeriods && (
        <ButtonDS
          buttonType="secondary"
          format="hug"
          singleIcon={{ icon: <DeleteOutlined />, size: "S" }}
          height="2"
          onClick={() => onDeleteAllowedPeriod(allowedPeriod)}
        />
      )}
    </StyledBodyDetails>
  );
};

const StyledBodyDetails = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLinkedWord = styled.div<{ $disabled: boolean }>`
  display: flex;
  color: ${({ $disabled }) =>
    $disabled ? colors["colors/text/lightGrey"] : colors["colors/text/black"]};
`;
