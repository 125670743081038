import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import { Badge } from "./Tag/Badge";
import Car from "../assets/CarGrey.png";
import { useTranslation } from "react-i18next";

export interface VehicleCardProps {
  registrationNumber: string | null;
  vehicleType: "SHARED" | "PERSONAL" | "OUT_OF_FLEET";
  reference?: string | null;
  vehicleModel?: string | null;
}

export const VehicleCard = (props: VehicleCardProps) => {
  const { t } = useTranslation();

  const { registrationNumber, vehicleType, reference, vehicleModel } = props;

  return (
    <StyledVehicleCard>
      <Spacer y={1} />
      <StyledFlex>
        <Spacer x={1} />
        <StyledImg src={Car} alt="Car" />
        <Spacer x={1.5} />
        <StyledVehicle>
          <StyledRegistrationNumber>
            {registrationNumber}
          </StyledRegistrationNumber>
          {vehicleModel && (
            <StyledModel>
              <Spacer y={0.25} />
              {vehicleModel}
            </StyledModel>
          )}
          <Spacer y={0.5} />
          <StyledFlex>
            <Badge
              text={t(`drivers.accessRequest.modal.vehicle.${vehicleType}`)}
              backgroundColor={
                colors["colors/system/informative/informative_veryLight"]
              }
              textColor={colors["colors/text/darkGrey"]}
            />
            <Spacer x={0.5} />
            {reference && (
              <Badge
                text={reference}
                backgroundColor={
                  colors["colors/system/informative/informative_veryLight"]
                }
                textColor={colors["colors/text/darkGrey"]}
              />
            )}
          </StyledFlex>
        </StyledVehicle>
        <Spacer x={1.5} />
      </StyledFlex>
      <Spacer y={1} />
    </StyledVehicleCard>
  );
};

const StyledVehicleCard = styled.div`
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
`;

const StyledVehicle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledImg = styled.img`
  width: 11rem;
  height: 3.5rem;
`;

const StyledRegistrationNumber = styled.div`
  ${typographies["Body/M"]};
  text-transform: uppercase;
`;

const StyledModel = styled.div`
  ${typographies["Body/XXS"]};
  color: ${colors["colors/text/darkGrey"]};
`;
