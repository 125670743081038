import styled from "styled-components";
import { Spacer } from "./Spacer";
import {
  dateFormatterHourMinute,
  dateFormatterWeekDayDayMonthYear,
} from "../../format";
import { TextCapitalized } from "./text/TextCapitalized";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import { Badge } from "./Tag/Badge";
import { Avatar } from "./Avatar/Avatar";
import { ClearRound } from "../materialUi/materialUi";
import { VehicleCard } from "./VehicleCard";

export type VehicleType = "SHARED" | "PERSONAL" | "OUT_OF_FLEET";

export interface Trip {
  secondsDuration: number | null;
  distance: number | null;
  ecoDrivingScore: number | null;
  startDate: string;
  endDate: string;
  driver: {
    firstName: string;
    lastName: string;
  } | null;
  durationVariation: number | null;
  distanceVariation: number | null;
}

export interface VehicleInfo {
  registrationNumber: string | null;
  reference: string | null;
  vehicleType: VehicleType;
  model: string | null;
}

export interface DayTripDetailsProps {
  date: Date | null;
  vehicle: VehicleInfo | null;
  trips: Trip[];
  onClose: () => void;
}

export const DayTripDetails = (props: DayTripDetailsProps) => {
  const { date, trips, vehicle } = props;

  return (
    <StyledDayTripDetailsContainer>
      <Spacer x={1.5} />
      <StyledColumn>
        <Spacer y={1.5} />
        <StyledTitle>
          <TextCapitalized>
            {date && dateFormatterWeekDayDayMonthYear(date)}
          </TextCapitalized>
          <StyledAroundCloseIcon onClick={props.onClose}>
            <ClearRound />
          </StyledAroundCloseIcon>
        </StyledTitle>
        <Spacer y={1.5} />
        <StyledContent>
          {trips.length !== 0 && (
            <StyledOverflow>
              {trips.map((t, index) => {
                const tripDisplay = {
                  distance: t.distance,
                  duration: t.secondsDuration
                    ? Math.round(t.secondsDuration / 60)
                    : null,
                  distanceVariation: t.distanceVariation,
                  durationVariation: t.durationVariation
                    ? Math.round(t.durationVariation / 60)
                    : t.durationVariation,
                  ecoDrivingScore: t.ecoDrivingScore
                    ? Number(t.ecoDrivingScore.toFixed(0))
                    : null,
                  driver: t.driver,
                };
                return (
                  <div key={index + "dayTrip"}>
                    {index !== 0 && <StyledLineGrey />}
                    <StyledFlex>
                      <DatesBarTrip
                        startDate={t.startDate}
                        endDate={t.endDate}
                      />
                      <Spacer x={1.5} />
                      <TripDetails tripDisplay={tripDisplay} />
                    </StyledFlex>
                  </div>
                );
              })}
            </StyledOverflow>
          )}
          {vehicle && (
            <>
              <Spacer x={1.5} />
              <StyledVehicleCard>
                <VehicleCard
                  registrationNumber={vehicle.registrationNumber}
                  vehicleType={vehicle.vehicleType}
                  reference={vehicle.reference}
                  vehicleModel={vehicle.model}
                />
              </StyledVehicleCard>
            </>
          )}
        </StyledContent>
        <Spacer y={1.5} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledDayTripDetailsContainer>
  );
};

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
  & svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: ${colors["colors/text/darkGrey"]};
  }
`;

export interface TripOptionsDisplay {
  distance: number | null;
  distanceVariation: number | null;
  duration: number | null;
  durationVariation: number | null;
  ecoDrivingScore: number | null;
  driver: {
    firstName: string;
    lastName: string;
  } | null;
}
export interface TripDetailsProps {
  tripDisplay: TripOptionsDisplay;
}
const tripDetailsOptions = [
  "distance",
  "distanceVariation",
  "duration",
  "durationVariation",
  "ecoDrivingScore",
];

export const TripDetails = (props: TripDetailsProps) => {
  const { t } = useTranslation();
  const { tripDisplay } = props;
  const { driver } = props.tripDisplay;

  return (
    <StyledTripContainer>
      <Spacer x={1.5} />
      <StyledColumn>
        {driver && (
          <>
            <Spacer y={1.5} />
            <StyledRowCenter>
              <TextCapitalized>
                <Avatar
                  size="M"
                  color="mint"
                  firstName={driver.firstName}
                  lastName={driver.lastName}
                />
              </TextCapitalized>
              <Spacer x={0.5} />
              {driver.firstName + " " + driver.lastName}
            </StyledRowCenter>
          </>
        )}
        <Spacer y={1.5} />
        <StyledFlex>
          {tripDetailsOptions.map((option, index) => {
            const tripDetailsOption = tripDisplay[
              option as keyof TripOptionsDisplay
            ] as string | number;

            return (
              <div key={index + "trip"}>
                {tripDisplay[option as keyof TripOptionsDisplay] !== null && (
                  <StyledFlex>
                    {index !== 0 && (
                      <Spacer x={option.includes("Variation") ? 0.5 : 1.5} />
                    )}
                    <StyledTripDetail>
                      <StyledOptionTitle
                        $isVariation={option.includes("Variation")}
                      >
                        <TextCapitalized>
                          {t(`trips.details.${option}.title`)}
                        </TextCapitalized>
                      </StyledOptionTitle>
                      <Spacer y={0.5} />
                      <Badge
                        text={
                          <StyledFlex>
                            {tripDetailsOption}
                            <Spacer x={0.25} />
                            {t(`trips.details.${option}.units`)}
                          </StyledFlex>
                        }
                        backgroundColor={getBadgeColors(
                          option,
                          tripDetailsOption,
                        )}
                        textColor={colors["colors/text/black"]}
                        typo={typographies["Body/XS"]}
                      />
                    </StyledTripDetail>
                  </StyledFlex>
                )}
              </div>
            );
          })}
        </StyledFlex>
        <Spacer y={1.5} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledTripContainer>
  );
};

export interface DatesBarTripProps {
  startDate: string;
  endDate: string;
}

export const DatesBarTrip = (props: DatesBarTripProps) => {
  return (
    <>
      <StyledDates>
        <div>
          {dateFormatterHourMinute(new Date(props.startDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
        <div>
          {dateFormatterHourMinute(new Date(props.endDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
      </StyledDates>
      <Spacer x={0.5} />
      <StyledBarDates>
        <StyledRound />
        <StyledLineGreen />
        <StyledRound />
      </StyledBarDates>
    </>
  );
};

const getPercentageColor = (value: number) =>
  value < 35
    ? colors["colors/system/error/error_veryLight"]
    : value > 75
      ? colors["colors/system/success/success_veryLight"]
      : colors["colors/system/warning/warning_veryLight"];

const getVariationColor = (
  value: number,
  threshold: [warning: number, danger: number],
) =>
  value > threshold[1]
    ? colors["colors/system/error/error_light"]
    : value < threshold[0]
      ? colors[`colors/system/success/success_veryLight`]
      : colors[`colors/system/warning/warning_veryLight`];

const getBadgeColors = (key: string, value: number | string | null): string => {
  if (value === null) return "";
  switch (key) {
    case "ecoDrivingScore":
      return getPercentageColor(value as number);
    case "durationVariation":
      return getVariationColor(value as number, [2, 13]);
    case "distanceVariation":
      return getVariationColor(value as number, [5, 25]);
    default:
      return colors["colors/system/informative/informative_veryLight"];
  }
};

const StyledDates = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 3.25rem;
  justify-content: space-between;
`;

const StyledLineGreen = styled.div`
  height: 100%;
  border-right: 0.75rem solid
    ${colors["colors/system/success/success_veryLight"]};
`;

const StyledRound = styled.div`
  min-height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 0.75rem;
  background: ${colors["colors/system/success/success_normal"]};
`;

const StyledBarDates = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledLineGrey = styled.div`
  height: 4rem;
  width: 4.5rem;
  border-right: 0.75rem solid
    ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledVehicleCard = styled.div`
  min-width: fit-content;
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledDayTripDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  ${typographies["Body/S"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/input/default"]};
`;

const StyledOverflow = styled.div`
  overflow: auto;
  width: 100%;
`;

const StyledTripContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
`;

const StyledTripDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H1"]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledOptionTitle = styled.div<{ $isVariation: boolean }>`
  color: ${({ $isVariation }) =>
    !$isVariation
      ? colors["colors/text/black"]
      : colors["colors/text/lightGrey"]};
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
