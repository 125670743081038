import styled from "styled-components";
import {
  DataGridPremium,
  GridColDef,
  GridValidRowModel,
  GridColType,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-premium";
import CustomToolbar from "./CustomToolbar";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import CustomColumnMenu from "./CustomColumnMenu";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { t } from "i18next";
import { defaultRenderCell } from "./CustomColumnsAggregation";
import { colors } from "../../../styles/figmaColors";
import { SearchBarDS, SearchBarDSProps } from "../SearchBar";
import { capitalize } from "../../../format";
import { EmptyTableComponent } from "../table/EmptyTableComponent";
import MaterialUiThemeProvider from "../../../providers/MaterialUiThemeProvider";

type MaterialUiTableProps<
  Key extends string,
  T extends Record<Key, string | number | null>,
> = {
  rows: GridValidRowModel[];
  columns: {
    [index in keyof T]: MaterialUiColumnsProps;
  };
  apiRef: MutableRefObject<GridApiPremium>;
  onRowClickAction?: (row: T) => void;
  dataGridState: GridInitialStatePremium;
  searchBar: SearchBarDSProps<T>;
  downloadViewAction?: (file: Buffer | undefined) => void;
  downloadDetailsAction?: () => void;
  pageName: string;
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  setRowHoverUuid?: (id: string | null) => void;
  rowToSendToTop?: string | null;
};

export type MaterialUiColumnsProps = {
  type: GridColType | undefined;
  valueFormatter?:
    | GridValueFormatter<GridValidRowModel, unknown, unknown, never>
    | undefined;
  renderCell?:
    | ((
        params: GridRenderCellParams<
          GridValidRowModel,
          unknown,
          unknown,
          GridTreeNodeWithRender
        >,
      ) => ReactNode)
    | undefined;
  valueGetter?:
    | GridValueGetter<GridValidRowModel, unknown, unknown, never>
    | undefined;
  width?: number;
};

const formatColumns = <
  Key extends string,
  T extends Record<Key, string | number | null>,
>(
  columnsDisplayed: { [index in keyof T]: MaterialUiColumnsProps },
  dataGridState: GridInitialStatePremium,
  pageName: string,
) => {
  const columnsKeys = Object.keys(columnsDisplayed);
  const columns: Record<string, GridColDef<GridValidRowModel>> = {};

  columnsKeys.forEach((k) => {
    const type = columnsDisplayed[k as keyof T].type;
    const width = columnsDisplayed[k as keyof T].width;
    const valueFormatter = columnsDisplayed[k as keyof T].valueFormatter;
    const renderCellParams = columnsDisplayed[k as keyof T].renderCell;
    const valueGetter = columnsDisplayed[k as keyof T].valueGetter;
    const renderCell = renderCellParams
      ? renderCellParams
      : type
        ? defaultRenderCell[type]?.renderCell
        : undefined;

    columns[k] = {
      field: k.toString(),
      headerName: capitalize(t(`${pageName}.column.${k}`)),
      sortable: true,
      pinnable: false,
      groupable: false,
      aggregable: type !== "date",
      width: width ?? 175,
      type,
      valueFormatter,
      valueGetter,
      renderCell,
    };
  });

  // Retrieve columns order from storage
  const orderedFields = dataGridState?.columns?.orderedFields ?? [];
  // Complete columns if doesn't exist last time
  columnsKeys.forEach((key) => {
    if (!orderedFields.includes(key)) orderedFields.push(key);
  });

  return orderedFields
    .filter((key) => columns[key]?.field)
    .map((key) => ({
      ...columns[key],
      // restore resized columns
      width: dataGridState?.columns?.dimensions
        ? dataGridState.columns.dimensions[key]?.width
        : columns[key].width,
    }));
};

export const MaterialUiTable = <
  Key extends string,
  T extends Record<Key, string | number | null>,
>({
  rows,
  columns,
  onRowClickAction,
  dataGridState,
  searchBar,
  downloadViewAction,
  downloadDetailsAction,
  apiRef,
  pageName,
  cta,
  cta2,
  setRowHoverUuid,
  rowToSendToTop,
}: MaterialUiTableProps<Key, T>) => {
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const setSearchValues = (values: T[]) => {
    searchBar?.setFilterValues(values);
    setIsSearchResult(values.length === 0);
  };
  const columnsFormatted = formatColumns(columns, dataGridState, pageName);
  const rowsWithIndex: (T & { index: number })[] = rows.map((r, index) => {
    return { ...(r as T), index: index };
  });

  useEffect(() => {
    if (!rowToSendToTop) return;
    const rowIndex =
      rowsWithIndex.find((row) => "id" in row && row["id"] === rowToSendToTop)
        ?.index ?? null;
    if (!rowIndex) return;

    const rowPosition = rowIndex * 55; // 55px height of the row
    apiRef.current.scroll({
      top: rowPosition,
    });
  }, [apiRef, rowToSendToTop, rowsWithIndex]);

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <SearchBarDS
          setFilterValues={setSearchValues}
          placeholder={t("home.searchbar")}
          values={searchBar.values}
          keysToTranslate={searchBar.keysToTranslate}
          findKeysToTranslate={searchBar.findKeysToTranslate}
          keysToIgnore={searchBar.keysToIgnore}
        />
      </StyledHeaderContainer>
      <MaterialUiThemeProvider>
        <StyledDataGrid
          apiRef={apiRef}
          rows={rowsWithIndex}
          columns={columnsFormatted}
          getRowHeight={({ densityFactor }) => 55 * densityFactor}
          columnHeaderHeight={60}
          slots={{
            toolbar: () => (
              <CustomToolbar
                downloadViewAction={downloadViewAction}
                downloadDetailsAction={downloadDetailsAction}
                pageName={pageName}
                apiRef={apiRef}
                dateFiltersFields={Object.keys(columns).filter(
                  (k) => columns[k as keyof typeof columns].type === "date",
                )}
              />
            ),
            columnMenu: CustomColumnMenu,
            noRowsOverlay: () => (
              <EmptyTableComponent
                pageName={pageName}
                isSearchResult={isSearchResult}
                cta={cta}
                cta2={cta2}
              />
            ),
          }}
          slotProps={{
            row: {
              onMouseEnter: (e) =>
                setRowHoverUuid?.(e.currentTarget.dataset.id ?? null),
              onMouseLeave: () => setRowHoverUuid?.(null),
            },
          }}
          onRowClick={(params) => {
            onRowClickAction && onRowClickAction(params.row as T);
          }}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.mileageDeviation}`
          }
          initialState={dataGridState}
          hideFooter
          sx={{
            border: "none",
            "& .MuiDataGrid-virtualScroller": {
              cursor: onRowClickAction ? "pointer" : "default",
              border: `0.0625rem solid ${colors["colors/borders/cells/cells"]}`,
              borderRadius: 2,
            },
            "& .MuiDataGrid-row": { "--rowBorderColor": "transparent" },
            "& .MuiDataGrid-cell": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-main": {
              height: rows.length === 0 ? "20rem" : "",
            },
            "& .MuiDivider-root": {
              border: "none",
            },
            "& .MuiDataGrid-row.Mui-selected": {
              background: `linear-gradient(to right, ${colors["colors/system/success/success_light"]} 0.2rem, #F5F5F5 0.2rem);`,
            },
            "& .MuiDataGrid-bottomContainer": {
              cursor: "default",
              borderTop: `0.0625rem solid ${colors["colors/borders/cells/cells"]}`,
            },
            ".MuiDataGrid-aggregationColumnHeaderLabel": {
              display: "none",
            },
          }}
        />
      </MaterialUiThemeProvider>
    </StyledContainer>
  );
};

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 5;
`;

const StyledContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
`;

const StyledDataGrid = styled(DataGridPremium)(() => ({
  "& .super-app-theme--sur-roulage": {
    backgroundColor: colors["colors/system/error/error_veryLight"],
    "&:hover": {
      backgroundColor: colors["colors/system/error/error_normal"],
    },
    "&.Mui-selected": {
      backgroundColor: colors["colors/system/error/error_dark"],
      "&:hover": {
        backgroundColor: colors["colors/system/error/error_normal"],
      },
    },
  },
  "& .super-app-theme--sous-roulage": {
    backgroundColor: colors["colors/system/warning/warning_ultraLight"],

    "&:hover": {
      backgroundColor: colors["colors/system/warning/warning_normal"],
    },
    "&.Mui-selected": {
      backgroundColor: colors["colors/system/warning/warning_dark"],
      "&:hover": {
        backgroundColor: colors["colors/system/warning/warning_normal"],
      },
    },
  },
  "& .super-app-theme--correct": {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    "&:hover": {
      backgroundColor: colors["colors/system/success/success_normal"],
    },
    "&.Mui-selected": {
      backgroundColor: colors["colors/system/success/success_dark"],
      "&:hover": {
        backgroundColor: colors["colors/system/success/success_normal"],
      },
    },
  },
}));
