import {
  ButtonDS,
  DialogModalDS,
  DownloadOutlined,
  RowElement,
  Spacer,
  VehicleCard,
} from "@qivia/ui";
import { useCallback, useEffect, useMemo } from "react";
import { QrCodeInfo } from "../cardsAPI";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  cardsSlice,
  getQrCodePresignedUrlAsync,
  qrCodeDownloadAsync,
  selectQrCodesUrl,
  selectQrCodesUrlStatus,
} from "../cardsSlice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VehicleType } from "../../vehicles/vehiclesAPI";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

type Information = {
  name: string | null;
  lastName?: string;
  uuid: string | null;
  reference?: string | null;
  type: VehicleType;
  model?: string | null;
};

type CardsQrCodesModalProps = {
  vehicle: Information;
  qrCodes: QrCodeInfo[];
  isQrCodeModalOpen: boolean;
  setIsQrCodeModalOpen: (b: boolean) => void;
};

export default function CardsQrCodesModal(props: CardsQrCodesModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const qrCodesUrl = useAppSelector(selectQrCodesUrl);
  const qrCodesUrlStatus = useAppSelector(selectQrCodesUrlStatus);

  const handleDownload = useCallback(
    (payload: { url: string | null; identifier: string }) => {
      if (!payload.url) return;
      void dispatch(
        qrCodeDownloadAsync({
          url: payload.url,
          fileName: payload.identifier + ".pdf",
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      qrCodesUrlStatus === "idle" &&
      props.qrCodes.length !== qrCodesUrl.length
    ) {
      props.qrCodes.forEach((qrCode) => {
        void dispatch(getQrCodePresignedUrlAsync(qrCode));
      });
    }
  }, [dispatch, props.qrCodes, qrCodesUrl, qrCodesUrlStatus]);

  const modalTitle = useMemo(
    () =>
      props.qrCodes.length > 1
        ? t("cards.sidePanel.modal.title.qrCodes")
        : t("cards.sidePanel.modal.title.qrCode"),
    [props.qrCodes.length, t],
  );

  return (
    <DialogModalDS
      visible={props.isQrCodeModalOpen}
      onClose={() => {
        props.setIsQrCodeModalOpen(false);
        void dispatch(cardsSlice.actions.resetUrlForQrCodes());
      }}
      title={modalTitle}
    >
      <StyledModal>
        <VehicleCard
          registrationNumber={props.vehicle.name ?? ""}
          reference={props.vehicle.reference ?? ""}
          vehicleType={props.vehicle.type}
          vehicleModel={props.vehicle.model}
        />
        <Spacer y={2.5} />
        {props.qrCodes.map((qrCode, i) => {
          const url =
            qrCodesUrl.find((q) => q.archiveFileName === qrCode.archiveFileName)
              ?.url ?? null;
          return (
            <div key={qrCode.identifier}>
              {i !== 0 && <Spacer y={1} />}
              <RowElement
                leftIcon={
                  url ? (
                    <StyledFlex>
                      <StyledIframe>
                        <iframe src={url} title={qrCode.archiveFileName} />
                      </StyledIframe>
                      <Spacer x={0.75} />
                    </StyledFlex>
                  ) : (
                    <></>
                  )
                }
                text={
                  <StyledTitle>
                    {t("cards.sidePanel.modal.item.title.qrCodes")}
                  </StyledTitle>
                }
                subText={<StyledText>{qrCode.identifier}</StyledText>}
                size="XL"
                borderRadius={0.5}
                withoutShadow
                rightElement={
                  <ButtonDS
                    buttonType="primary"
                    format="hug"
                    leftIcon={<DownloadOutlined />}
                    text={t("cards.sidePanel.button.downloadQrCode")}
                    sizeButton="S"
                    onClick={() =>
                      handleDownload({
                        url,
                        identifier: qrCode.identifier,
                      })
                    }
                  />
                }
              />
            </div>
          );
        })}
      </StyledModal>
    </DialogModalDS>
  );
}

const StyledIframe = styled.div`
  display: flex;
  iframe {
    width: 3rem;
    height: 3rem;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledModal = styled.div`
  width: 100%;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H5"]}
`;

const StyledText = styled.div`
  ${typographies["Body/S"]}
`;
