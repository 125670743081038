import { ComponentPropsWithRef, forwardRef, useState } from "react";
import { typographies } from "../../../styles/figmaTypographies";
import { colors } from "../../../styles/figmaColors";
import styled from "styled-components";
import { TextCapitalized } from "../../../designSystem/components/text/TextCapitalized";
import { Spacer } from "./../Spacer";
import capitalize from "@mui/material/utils/capitalize";

export interface TextAreaProps extends ComponentPropsWithRef<"textarea"> {
  value: string;
  update: (e: string) => void;
  error?: string;
  isDisabled?: boolean;
  placeholder?: string;
  width?: number;
}

export const TextAreaDS = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, isDisabled, placeholder, width, ...props }, ref) => {
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false);

    const updateValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const text = (e.target as HTMLTextAreaElement).value;
      if (!text || text === "") {
        return props.update("");
      }
      return props.update(text);
    };

    return (
      <>
        <StyledTextArea
          $isDisabled={isDisabled}
          $isError={error !== undefined}
          $isInputFocus={isInputFocus}
          $width={width}
          ref={ref as React.Ref<HTMLTextAreaElement>}
          {...props}
          placeholder={placeholder && capitalize(placeholder)}
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
            setIsInputFocus(false);
          }}
          onChange={updateValue}
          disabled={isDisabled}
        />
        {error && (
          <StyledErrorMessage>
            <Spacer x={0.25} />
            <TextCapitalized>{error}</TextCapitalized>
          </StyledErrorMessage>
        )}
      </>
    );
  },
);

const StyledTextArea = styled.textarea<{
  $isError: boolean;
  $isDisabled?: boolean;
  $isInputFocus: boolean;
  $width?: number;
}>`
  display: flex;
  ${typographies["Body/XS"]};
  width: ${({ $width }) => ($width ? `${$width + 2}rem` : "100%")};
  min-height: 4rem;
  padding: 0.5rem;
  color: ${({ $isDisabled }) =>
    $isDisabled
      ? colors["colors/text/lightGrey"]
      : colors["colors/text/black"]};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "auto")};
  resize: vertical;
  &::placeholder {
    ${typographies["Body/S"]};
    color: ${colors["colors/text/lightGrey"]};
  }
  &:focus {
    outline: none;
    background-size:
      100% 0.125rem,
      100% 0.0625rem;
  }
  background-color: ${({ $isDisabled }) =>
    $isDisabled
      ? colors["colors/surfaces/background/background_level1"]
      : colors["colors/surfaces/background/background_level0"]};
  border: 0.0625rem solid
    ${({ $isError, $isInputFocus }) =>
      $isError
        ? colors["colors/system/error/error_normal"]
        : $isInputFocus
          ? colors["colors/accent/500"]
          : colors["colors/borders/input/default"]};
  border-radius: 0.25rem;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  ${typographies["Body/XXS"]};
  color: ${colors["colors/system/error/error_normal"]};
`;
