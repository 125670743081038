import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  CachedOutlined,
  DriverCard,
  KeyboardArrowRightOutlined,
  NavigationCard,
  PageDS,
  Spacer,
  TextCapitalized,
  VehicleCard,
  triggerToast,
} from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  driversSlice,
  updateDriverAccessRequestAsync,
  selectCurrentDriverAccessRequest,
} from "./driversSlice";
import { useCallback, useEffect } from "react";
import {
  DriverAccessRequestStatus,
  UpdateDriverAccessRequest,
} from "./driversAPI";
import { selectCompany } from "../homeSlice";

export const DriverAccessRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routerParams = useParams();
  const paramsUuid = routerParams.id;
  const currentDriverAccessRequest = useAppSelector(
    selectCurrentDriverAccessRequest,
  );
  const company = useAppSelector(selectCompany);

  const onSubmit = useCallback(
    (status: DriverAccessRequestStatus) => {
      if (currentDriverAccessRequest && company) {
        const payload: UpdateDriverAccessRequest = {
          uuid: currentDriverAccessRequest.uuid,
          status,
          companyUuid: company.uuid,
        };
        void dispatch(updateDriverAccessRequestAsync(payload));
      }
    },
    [currentDriverAccessRequest, dispatch, company],
  );

  const onClose = useCallback(() => {
    void dispatch(driversSlice.actions.resetDriversAccessRequestsListStatus());
    navigate(`/home/drivers`);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (
      currentDriverAccessRequest &&
      currentDriverAccessRequest.uuid !== paramsUuid
    ) {
      navigate(
        `/home/drivers/access_request/${currentDriverAccessRequest.uuid}`,
      );
    } else if (currentDriverAccessRequest === null) {
      void dispatch(
        driversSlice.actions.resetDriversAccessRequestsListStatus(),
      );
      triggerToast(t(`drivers.accessRequest.success`) || "", "valid");
      navigate(`/home/drivers`);
    }
  }, [currentDriverAccessRequest, dispatch, navigate, paramsUuid, t]);

  if (!currentDriverAccessRequest) return;

  return (
    <PageDS
      title={t("drivers.accessRequest.modal.title")}
      titleElement={{ closeButtonAction: () => onClose() }}
    >
      <StyledAccessRequest>
        <StyledColumns>
          <Badge
            text={t("drivers.accessRequest.modal.badge")}
            backgroundColor={colors["colors/system/warning/warning_veryLight"]}
            textColor={colors["colors/system/warning/warning_dark"]}
          />
          <Spacer y={1} />
          <StyledTitle>
            <TextCapitalized>
              {t("drivers.accessRequest.modal.question")}
            </TextCapitalized>
          </StyledTitle>

          <Spacer y={2} />
          <NavigationCard
            title={t("drivers.accessRequest.modal.authorize.title")}
            subtitle={t("drivers.accessRequest.modal.authorize.subtitle")}
            onClick={() => onSubmit("ACCEPTED")}
            element={<KeyboardArrowRightOutlined />}
          />
          <Spacer y={0.5} />
          <NavigationCard
            title={t("drivers.accessRequest.modal.refuse.title")}
            subtitle={t("drivers.accessRequest.modal.refuse.subtitle")}
            onClick={() => onSubmit("REJECTED")}
            element={<KeyboardArrowRightOutlined />}
          />
          <Spacer y={2} />
        </StyledColumns>
        <Spacer x={3} />
        <StyledColumns>
          <Spacer y={3} />
          <StyledRow>
            <Spacer x={2} />
            <StyledColumn>
              <DriverCard
                firstName={
                  currentDriverAccessRequest.driverCandidateFistName ?? ""
                }
                lastName={currentDriverAccessRequest.driverCandidateLastName}
                badge={
                  <Badge
                    text={t("drivers.accessRequest.modal.driver.new.badge")}
                    backgroundColor={
                      colors["colors/system/informative/informative_ultraLight"]
                    }
                    textColor={
                      colors["colors/system/informative/informative_normal"]
                    }
                  />
                }
              />
              <Spacer y={0.5} />
              <StyledCached>
                <CachedOutlined />
              </StyledCached>
              <Spacer y={0.5} />
              <DriverCard
                firstName={currentDriverAccessRequest.driverOriginalFistName}
                lastName={currentDriverAccessRequest.driverOriginalLastName}
              />
              <Spacer y={1} />
              <VehicleCard
                registrationNumber={
                  currentDriverAccessRequest.vehicleRegistrationNumber
                }
                vehicleType={currentDriverAccessRequest.vehicleType}
                reference={currentDriverAccessRequest.vehicleReference}
                vehicleModel={[
                  currentDriverAccessRequest.vehicleBrand,
                  currentDriverAccessRequest.vehicleModel,
                ]
                  .filter((d) => d)
                  .join(" ")}
              />
            </StyledColumn>
            <Spacer x={2} />
          </StyledRow>
        </StyledColumns>
      </StyledAccessRequest>
    </PageDS>
  );
};

const StyledAccessRequest = styled.div`
  display: flex;
  width: 100%;
`;

const StyledColumns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledCached = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

// no typo
const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
`;
