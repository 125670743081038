import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

import { MutableRefObject, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";
import { createFileXlsx } from "./createFileXlsx";
import {
  DownloadOutlined,
  KeyboardArrowDownOutlined,
} from "../../materialUi/materialUi";
import { ButtonDS } from "../Button";
import { Spacer } from "../Spacer";
import { colors } from "../../../styles/figmaColors";
import { dateFormatterDayMonthYearLong2Digits } from "../../../format";
import MaterialUiDatePicker from "../materialDatePicker/MaterialUiDatepicker";

type ActionProps = {
  columns: string[];
  onClick: (k: string) => void;
  className: string;
  pageName: string;
  columnFiltered: string;
};

function Action({
  columns,
  onClick,
  className,
  pageName,
  columnFiltered,
}: ActionProps) {
  return (
    <StyledFlex className={className}>
      <Spacer x={1} />
      <StyledColumn>
        {columns.map((col) => (
          <div key={col}>
            <Spacer y={1} />
            <ButtonDS
              text={t(`${pageName}.column.${col}`)}
              onClick={() => onClick(col)}
              buttonType={columnFiltered === col ? "primary" : "secondary"}
              format={"hug"}
            />
          </div>
        ))}
      </StyledColumn>
    </StyledFlex>
  );
}

const getRowsData = (apiRef: MutableRefObject<GridApiPremium>) => {
  if (apiRef.current) {
    const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup;
    const sortedRows = apiRef.current.getSortedRows();
    return sortedRows.filter((row) => filteredRowsLookup[row.id as string]);
  }
};

const columnsToHideInView = ["type", "opendetails", ""];

const getDownloadFileBuffer = <
  Key extends string,
  T extends Record<Key, string | number>,
>(
  apiRef: MutableRefObject<GridApiPremium>,
  pageName: string,
) => {
  if (!apiRef.current) return;
  const columns = apiRef.current.getVisibleColumns() ?? [];
  const headerTitles = columns
    .map((column) => column.headerName ?? "")
    .filter((h) => !columnsToHideInView.includes(h.toLowerCase()));
  const headerKeys = columns
    .map((column) => column.field)
    .filter(
      (h) => !columnsToHideInView.includes(h.toLowerCase()),
    ) as (keyof T)[];
  const gridContent = getRowsData(apiRef) as T[];

  const bodyList = gridContent.map((l) => {
    return headerKeys.map((k) => {
      if (k === "stationsInfo") {
        return l[k].toString()?.replace("<br/>", " / ") ?? "";
      } else {
        return l[k] instanceof Date
          ? dateFormatterDayMonthYearLong2Digits(l[k])
          : (l[k] ?? "");
      }
    });
  });

  return createFileXlsx({
    tabTitle: t(`${pageName}.view.title`),
    headerTitles,
    body: bodyList,
  });
};

type ToolbarProps = {
  downloadViewAction?: (file: Buffer | undefined) => void;
  downloadDetailsAction?: () => void;
  pageName: string;
  apiRef: MutableRefObject<GridApiPremium>;
  dateFiltersFields?: string[];
};

export default function CustomToolbar(props: ToolbarProps) {
  const {
    downloadViewAction,
    downloadDetailsAction,
    dateFiltersFields,
    pageName,
  } = props;
  const ref = props.apiRef.current;
  const [columnFiltered, setColumnFiltered] = useState<string | null>(
    dateFiltersFields ? dateFiltersFields[0] : null,
  );
  const [dateRange, setDateRange] = useState<Date[] | null>(null);

  const dateFilterValue: [start: Date, end: Date] | undefined = useMemo(() => {
    if (!ref?.state.filter) return undefined;

    const storedDateFilterValues = ref.state.filter.filterModel.items
      .filter((item) => dateFiltersFields?.includes(item.field))
      .map((item) => item.value as Date);

    return storedDateFilterValues.length === 2
      ? [storedDateFilterValues[0], storedDateFilterValues[1]]
      : undefined;
  }, [dateFiltersFields, ref.state.filter]);

  useEffect(() => {
    if (!ref) return;
    if (
      !columnFiltered ||
      dateRange?.length !== 2 ||
      !dateRange[0] ||
      !dateRange[1]
    ) {
      const oldFilters = ref.state.filter.filterModel.items;
      const startFilter = oldFilters.find((filter) => filter.id === "start");
      const endFilter = oldFilters.find((filter) => filter.id === "end");
      if (!startFilter || !endFilter) return;
      setColumnFiltered(startFilter.field);
      setDateRange([startFilter.value, endFilter.value]);
      return;
    }

    ref.upsertFilterItems([
      {
        id: "start",
        field: columnFiltered,
        operator: "onOrAfter",
        value: dateRange[0],
      },
      {
        id: "end",
        field: columnFiltered,
        operator: "onOrBefore",
        value: dateRange[1],
      },
    ]);
  }, [dateRange, columnFiltered, ref]);

  return (
    <>
      <StyledHeaderContainer>
        <Spacer x={20} />
        {downloadViewAction && (
          <>
            <Spacer x={1.5} />
            <ButtonDS
              format={"hug"}
              buttonType={"secondary"}
              onClick={() => {
                downloadViewAction &&
                  downloadViewAction(
                    getDownloadFileBuffer(props.apiRef, props.pageName),
                  );
              }}
              text={t("excel.downloadView")}
              leftIcon={<DownloadOutlined />}
              height="2.7"
            />
          </>
        )}
        {downloadDetailsAction && (
          <>
            <Spacer x={1.5} />
            <ButtonDS
              format={"hug"}
              buttonType={"secondary"}
              onClick={() => {
                downloadDetailsAction && downloadDetailsAction();
              }}
              text={t("excel.downloadDetails")}
              leftIcon={<DownloadOutlined />}
              height="2.7"
            />
          </>
        )}
        <Spacer x={1.5} />
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              startIcon: "",
              endIcon: <KeyboardArrowDownOutlined />,
              sx: {
                border: `0.0625rem solid ${colors["colors/borders/button/primary"]}`,
                height: "2.7rem",
                borderRadius: "0.5rem",
                width: "7rem",
                boxShadow: "2px 1px 4px 0px rgba(122, 122, 122, 0.08)",
              },
            },
          }}
        />
        <Spacer x={1.5} />
        <GridToolbarFilterButton
          slotProps={{
            button: {
              endIcon: <KeyboardArrowDownOutlined />,
              sx: {
                border: `0.0625rem solid ${colors["colors/borders/button/primary"]}`,
                height: "2.7rem",
                borderRadius: "0.5rem",
                width: "7rem",
                boxShadow: "2px 1px 4px 0px rgba(122, 122, 122, 0.08)",
              },
            },
          }}
        />
        {dateFiltersFields?.length !== 0 && (
          <>
            <Spacer x={1.5} />
            <MaterialUiDatePicker
              onChange={setDateRange}
              value={dateFilterValue}
              Action={(props) => (
                <Action
                  {...props}
                  columns={dateFiltersFields}
                  onClick={(col: string) => setColumnFiltered(col)}
                  pageName={pageName}
                  columnFiltered={columnFiltered}
                />
              )}
            />
          </>
        )}
      </StyledHeaderContainer>
      <Spacer y={1.5} />
    </>
  );
}

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    width: 1.25rem;
    height: 1.1rem;
  }
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
