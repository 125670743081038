import styled from "styled-components";
import { Spacer } from "./Spacer";
import { SelectDS } from "./Select";

interface SelectTimeProps {
  beginningTime: number;
  endingTime: number;
  onChange: (value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  error?: boolean;
}

interface Option<T extends string> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

export const SelectTimeDS = (props: SelectTimeProps) => {
  const handleChange = (selectedOption: Option<string> | null) => {
    props.onChange(selectedOption?.value ?? null);
  };

  const timeValue = (beginningTime: number, endingTime: number) => {
    const options: { value: string; label: string }[] = [];
    for (let i = beginningTime; i <= endingTime; i++) {
      options.push({
        value: `${i < 10 ? "0" + i.toString() : i}:00:00`,
        label: `${i < 10 ? "0" + i.toString() : i} h`,
      });
    }
    return options as Option<string>[];
  };

  const options = timeValue(props.beginningTime, props.endingTime);
  const valueOption = options.find((option) => option.value === props.value);

  return (
    <SelectContainer>
      <SelectDS
        onChange={handleChange}
        options={options}
        value={valueOption}
        disabled={props.disabled}
        label=""
        width={4}
        error={props.error ? "true" : ""}
        withoutErrorMessage
      />
      <Spacer y={1.5} />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 5.5rem;
  height: 2.5rem;
`;
