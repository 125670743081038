import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import {
  DriversAPI,
  DriversListType,
  UpdateDriverStatusType,
  PendingDriverAccessRequest,
  UpdateDriverAccessRequest,
  DriverStatusType,
  UpdateDriverIdNumberType,
} from "./driversAPI";
import { RootState } from "../../../redux/store";
import { capitalize } from "@qivia/ui/src/format";

export interface DriversState {
  driversListStatus: QueryStatus;
  driversList: DriversListType[];
  driverUpdatedStatus: QueryStatus;
  driverUpdatedIdNumberStatus: QueryStatus;
  driverAccessRequestsListStatus: QueryStatus;
  driverAccessRequestsList: PendingDriverAccessRequest[];
  currentDriverAccessRequest: PendingDriverAccessRequest | null;
  driverNewStatus: DriverStatusType | null;
}

const initialState: DriversState = {
  driversListStatus: "idle",
  driversList: [],
  driverUpdatedStatus: "idle",
  driverUpdatedIdNumberStatus: "idle",
  driverAccessRequestsListStatus: "idle",
  driverAccessRequestsList: [],
  currentDriverAccessRequest: null,
  driverNewStatus: null,
};

export const driversListAsync = createAsyncThunk(
  "driversList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<DriversAPI>>(
      `drivers/company/${companyUuid}`,
      { timeout: 300000 },
    );
    const driversList = response.data.map((payload) => ({
      ...payload,
      date: payload.createdAt,
      name: capitalize(payload.firstName) + " " + capitalize(payload.lastName),
      vehicleReference: payload.vehicleReference?.toLocaleUpperCase() ?? null,
      averageMonthlyAmount: payload.averageMonthlyAmount
        ? Number((payload.averageMonthlyAmount / 100).toFixed(2))
        : null,
      transactionsWithoutSupportingDocumentCount:
        payload.missingDataTransactionCount
          ?.transactionsWithoutSupportingDocumentCount ?? null,
      transactionsWithoutMileageCount:
        payload.missingDataTransactionCount?.transactionsWithoutMileageCount ??
        null,
      averageQuantity: payload.averageQuantity
        ? Number(payload.averageQuantity.toFixed(2))
        : null,
    }));
    return driversList;
  },
);

export const driversAccessRequestsListAsync = createAsyncThunk(
  "driversAccessRequestsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<PendingDriverAccessRequest>>(
      `pending_access_requests/company/${companyUuid}`,
    );
    return response.data;
  },
);

export const updateDriverAccessRequestAsync = createAsyncThunk(
  "updateDriverAccessRequest/call",
  async (payload: UpdateDriverAccessRequest, thunkAPI) => {
    const axios = authAxios();
    await axios.put(
      `pending_access_request/${payload.uuid}/${payload.status}/company/${payload.companyUuid}`,
    );
    void thunkAPI.dispatch(driversAccessRequestsListAsync(payload.companyUuid));
  },
);

export const updateDriverStatusAsync = createAsyncThunk(
  "updateDriverStatus/call",
  async (payload: UpdateDriverStatusType) => {
    const axios = authAxios();
    await axios.put(`/driver/${payload.driverUuid}/update_status`, {
      status: payload.status,
      companyUuid: payload.companyUuid,
    });
    return payload.status;
  },
);

export const updateDriverIdNumberAsync = createAsyncThunk(
  "updateDriverIdNumber/call",
  async (payload: UpdateDriverIdNumberType) => {
    const axios = authAxios();
    await axios.put(`/driver/${payload.driverUuid}/update_id_number`, {
      idNumber: payload.idNumber,
      companyUuid: payload.companyUuid,
    });
  },
);

export const driversSlice = createSlice({
  name: "vehicles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDriversListStatus(state) {
      state.driversListStatus = "idle";
    },
    resetDriversAccessRequestsListStatus(state) {
      state.driverAccessRequestsListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(driversListAsync.pending, (state) => {
        state.driversListStatus = "processing";
      })
      .addCase(driversListAsync.fulfilled, (state, action) => {
        state.driversList = action.payload;
        state.driversListStatus = "success";
        state.driverUpdatedStatus = "idle";
        state.driverUpdatedIdNumberStatus = "idle";
        state.driverNewStatus = null;
      })
      .addCase(driversListAsync.rejected, (state) => {
        state.driversListStatus = "failed";
      })
      .addCase(updateDriverStatusAsync.pending, (state) => {
        state.driverUpdatedStatus = "processing";
      })
      .addCase(updateDriverStatusAsync.fulfilled, (state, action) => {
        state.driverUpdatedStatus = "success";
        state.driverNewStatus = action.payload;
      })
      .addCase(updateDriverStatusAsync.rejected, (state) => {
        state.driverUpdatedStatus = "failed";
      })
      .addCase(driversAccessRequestsListAsync.pending, (state) => {
        state.driverAccessRequestsListStatus = "processing";
      })
      .addCase(driversAccessRequestsListAsync.fulfilled, (state, action) => {
        state.driverAccessRequestsList = action.payload;
        state.currentDriverAccessRequest = action.payload[0] ?? null;
        state.driverAccessRequestsListStatus = "success";
      })
      .addCase(driversAccessRequestsListAsync.rejected, (state) => {
        state.driverAccessRequestsListStatus = "failed";
      })
      .addCase(updateDriverIdNumberAsync.pending, (state) => {
        state.driverUpdatedIdNumberStatus = "processing";
      })
      .addCase(updateDriverIdNumberAsync.fulfilled, (state) => {
        state.driverUpdatedIdNumberStatus = "success";
      })
      .addCase(updateDriverIdNumberAsync.rejected, (state) => {
        state.driverUpdatedIdNumberStatus = "failed";
      });
  },
});

export const selectDriversList = (state: RootState) =>
  state.drivers.driversList;

export const selectDriversListStatus = (state: RootState) =>
  state.drivers.driversListStatus;

export const selectDriverUpdatedStatus = (state: RootState) =>
  state.drivers.driverUpdatedStatus;

export const selectDriverAccessRequestsListStatus = (state: RootState) =>
  state.drivers.driverAccessRequestsListStatus;

export const selectDriverAccessRequestsList = (state: RootState) =>
  state.drivers.driverAccessRequestsList;

export const selectCurrentDriverAccessRequest = (state: RootState) =>
  state.drivers.currentDriverAccessRequest;

export const selectDriverNewStatus = (state: RootState) =>
  state.drivers.driverNewStatus;

export const selectDriverIdNumberUpdatedStatus = (state: RootState) =>
  state.drivers.driverUpdatedIdNumberStatus;

export default driversSlice.reducer;
