import { useTranslation } from "react-i18next";
import { AllowedPeriodType, DaysOfWeekType } from "../../rulesAPI";
import { useCallback } from "react";
import { allowedPeriodsDatas } from "../../libDefaultDatas";
import { AddRound, ButtonDS, CheckBox, Spacer } from "@qivia/ui";
import styled from "styled-components";
import { StyledBodyLeft } from "../styledBodyLeft";
import { AllowedPeriodDetails } from "./allowedPeriodDetails";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const AllowedPeriodsDayOfWeek = (props: {
  oldValue: AllowedPeriodType[];
  onChange: (value: AllowedPeriodType[]) => void;
}) => {
  const { onChange, oldValue } = props;
  const { t } = useTranslation();

  const addAllowedDay = useCallback(
    (dayOfWeek: string) => {
      const allowedPeriods = oldValue;
      const isDayAlreadyAllowed = allowedPeriods.find((allowedPeriod) => {
        return allowedPeriod.dayOfWeek === (dayOfWeek as DaysOfWeekType);
      });

      if (isDayAlreadyAllowed) {
        onChange(
          allowedPeriods.filter(
            (allowedPeriod) => allowedPeriod.dayOfWeek !== dayOfWeek,
          ),
        );
      } else {
        onChange([
          ...allowedPeriods,
          {
            dayOfWeek: dayOfWeek,
            startTime: "00:00:00",
            endTime: "24:00:00",
          },
        ]);
      }
    },
    [onChange, oldValue],
  );

  const onAddAllowedPeriod = useCallback(
    (dayOfWeek: string, allowedPeriodsSorted: AllowedPeriodType[]) => {
      const lastAllowedPeriod =
        allowedPeriodsSorted[allowedPeriodsSorted.length - 1];
      const lastAllowedPeriodHour = lastAllowedPeriod?.endTime
        ? Number(lastAllowedPeriod.endTime.substring(0, 2))
        : 0;
      const beginningTime = lastAllowedPeriodHour + 1;
      const oldAllowedPeriods = oldValue;
      const newAllowedPeriods = [
        ...oldAllowedPeriods,
        {
          dayOfWeek: dayOfWeek,
          startTime: `${beginningTime < 10 ? "0" + beginningTime.toString() : beginningTime}:00:00`,
          endTime: "24:00:00",
        },
      ];
      onChange(newAllowedPeriods);
    },
    [oldValue, onChange],
  );

  const noLargerAllowedPeriodExists = useCallback(
    (allowedPeriods: AllowedPeriodType[]) => {
      const lastAllowedPeriod = allowedPeriods[allowedPeriods.length - 1];
      return (
        lastAllowedPeriod.endTime === "24:00:00" ||
        lastAllowedPeriod.endTime === "23:00:00"
      );
    },
    [],
  );

  return (
    <>
      {allowedPeriodsDatas.map((allowedPeriod, index) => {
        const isAllowedPeriodInOldValue = allowedPeriod
          ? oldValue.find((a) => a.dayOfWeek === allowedPeriod.dayOfWeek)
          : undefined;

        const allowedPeriodsSorted = oldValue
          .filter((oldAllowedPeriod) => {
            return oldAllowedPeriod.dayOfWeek === allowedPeriod.dayOfWeek;
          })
          .sort((a, b) => {
            return a.startTime.localeCompare(b.startTime);
          });

        const hasAllowedPeriods = allowedPeriodsSorted.length > 0;
        const hasMultipleAllowedPeriods = allowedPeriodsSorted.length > 1;

        //handle if no allowed periods for days
        if (!hasAllowedPeriods) {
          allowedPeriodsSorted.push(allowedPeriod);
        }

        const canAddAllowedPeriods =
          hasAllowedPeriods &&
          !noLargerAllowedPeriodExists(
            allowedPeriodsSorted.filter(
              (ap) => ap.dayOfWeek === allowedPeriod.dayOfWeek,
            ),
          );

        return (
          <StyledAllowedPeriodsContainer key={index + "container"}>
            <Spacer x={1.5} />
            <StyledColumn key={index + "column"}>
              <Spacer y={1} />
              <StyledFlex
                key={index + "flex"}
                $hasMultipleAllowedPeriods={hasMultipleAllowedPeriods}
              >
                <StyledBodyLeft key={index + "body left"}>
                  <StyledColumn>
                    {hasMultipleAllowedPeriods && <Spacer y={0.25} />}
                    <CheckBox
                      keyProp={index + "day"}
                      text={t(
                        `rules.modal.dayOfWeek.${allowedPeriod.dayOfWeek.toLowerCase()}`,
                      )}
                      state={
                        isAllowedPeriodInOldValue ? "CHECKED" : "UNCHECKED"
                      }
                      onClick={() => addAllowedDay(allowedPeriod.dayOfWeek)}
                    />
                  </StyledColumn>
                </StyledBodyLeft>
                <StyledColumn>
                  {allowedPeriodsSorted.map((ap, apIndex) => {
                    return (
                      <AllowedPeriodDetails
                        key={apIndex + "details"}
                        oldValue={oldValue}
                        allowedPeriod={ap}
                        onChange={onChange}
                        index={apIndex}
                        hasAllowedPeriods={hasAllowedPeriods}
                      />
                    );
                  })}
                </StyledColumn>
                <Spacer x={1.5} />
                <StyledButton>
                  <StyledColumn>
                    {hasMultipleAllowedPeriods && <Spacer y={0.25} />}
                    <ButtonDS
                      buttonType="secondary"
                      format="hug"
                      singleIcon={{ icon: <AddRound />, size: "S" }}
                      height="2"
                      onClick={() => {
                        canAddAllowedPeriods &&
                          onAddAllowedPeriod(
                            allowedPeriod.dayOfWeek,
                            allowedPeriodsSorted,
                          );
                      }}
                      disabled={!canAddAllowedPeriods}
                    />
                  </StyledColumn>
                </StyledButton>
              </StyledFlex>
              <Spacer y={1} />
            </StyledColumn>
            <Spacer x={1.5} />
          </StyledAllowedPeriodsContainer>
        );
      })}
    </>
  );
};
const StyledFlex = styled.div<{ $hasMultipleAllowedPeriods: boolean }>`
  display: flex;
  align-items: ${({ $hasMultipleAllowedPeriods }) =>
    $hasMultipleAllowedPeriods ? "flex-start" : "center"};
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledAllowedPeriodsContainer = styled.div`
  display: flex;
  width: 100%;
  border-top: 0.0625rem solid ${colors["colors/borders/button/primary"]};
`;
